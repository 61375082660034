export const environment = {
  authUrl:
    "https://qimclkv45b.execute-api.us-east-1.amazonaws.com/dev/oktaSignIn",
  clientId: "0oa22c2c4bdesMY820h8",
  hasuraApiSecret:
    "4YoodAfVR5u6UHGiajvayY0i5P4cki6TmbEE1kHdKH7haFsJgSYEjUtW5S8Hv4e3",
  hasuraApiUrl: "https://startup-poc.hasura.app/v1/graphql",
  issuer: "https://carrier.oktapreview.com",
  uploadServiceUrl: "https://platform.preprod.iot.carrier.com",
};
